import React, {useState} from "react";
import FullScreenSection from "./FullScreenSection";
import { Box, Heading } from "@chakra-ui/react";
import Card from "./Card";

const projects = [
  {
    title: "Web OS 桌面",
    link: 'https://desktop.tingqi.xyz',
    description: "os based web tech",
    markdown: `1. [访问: https://desktop.tingqi.xyz](https://desktop.tingqi.xyz)
2. 自定义导航栏, 背景
![busheng](https://storage.tingqi.xyz/desktop1.png)
3. dify Chat
![busheng](https://storage.tingqi.xyz/desktop2.png)

4. 参考:
	1. [puter](https://github.com/HeyPuter/puter)
	2. [arozos](https://arozos.com)`,
    getImageSrc: () => require("../images/desktop.png"),
  },
  {
    title: "步升无人店管理系统",
    link: '',
    description: "takeout order and nobody store inventory management",
    markdown: `
1. 服务结构：![busheng](https://storage.tingqi.xyz/busheng0.png)
2. 技术栈：
	1. 核心代码后端使用Django， django-ninja，使用Sanic提供部分独立功能和异步任务管理
	2. 核心前端代码使用Vue3， Quasar，Echart
3. 系统结构：
	1. 基础系统管理功能：用户，权限，维护
	2. 公司基础数据：商品，门店，供应商，仓库，员工
	3. 订单处理：订单状态管理， 订单商品修改，聚合配送管理，订单信息推送
	4. 库存管理： 总仓和100+门店出入库，总仓采购，退货，物流发货，门店补货，动态盘点，动态安全库存计算
	5. 数据分析：门店分平台订单量，商品销量，仓库出入库统计
	6. 云客服管理
	7. 平台IM消息管理
	8. 门店设备管理：门店显示设备状态
4. 第三方交互：
	5. 美团，饿了么开放平台接收IM,订单信息
	6. 聚合配送平台接收，修改订单配送信息
	7. SMS消息推送骑手
	8. 快递物流信息推送`,
    getImageSrc: () => require("../images/busheng0.png"),
  },  
  {
    title: "李尔立体库WMS",
    description: "JiNing Lear WMS",
    link: '',
    markdown: `1. 项目整体概况：
	1. 项目包括一个半成品穿梭车立体库，一个中间工序堆垛机立体库， 一个原材料堆垛机立体库，地面AGV以及多个仓库间钢平台滚筒输送线，整个项目为李尔的生产提供原材料，半成品流转完整自动化解决方案
	2. WMS系统主要功能：
		1. 仓库内自动化设备调度，设备状态，任务执行管理 
		2. 库存管理： 出入库， 盘点，异常， 统计
		3. 仓库内容器，库位管理， 容器实时位置
		4. 与李尔MES系统交互， 同步库存状态， 接收生产计划实现自动化出入库 
2. 技术栈：  
	1. 核心代码后端使用Django， djangorestframework,  django-ninja，daphne, 
	2. 核心前端代码使用Vue2， Quasar  
3. 系统功能：  
	1. 基础系统管理功能：用户，权限，维护 
	2. 李尔基础数据管理： 原材料，工序
	3. 穿梭车， 堆垛机， agv管理： 设备状态， 任务调度， 任务状态， 异常处理 
	4. 拣选工位管理
	5. 库存管理: 出入库， 盘点， 
	6. 数据统计：任务执行统计， 出入库统计， 设备故障分析
	7.  生产计划管理
4. 第三方交互：
	5.  Rayo 堆垛机， 输送线WCS
	6. 新松穿梭车WCS
	7. 海康AGV RCS
	8. 李尔MES
5. 系统界面 ![system](https://storage.tingqi.xyz/lear0.png)![system](https://storage.tingqi.xyz/lear1.png)![system](https://storage.tingqi.xyz/lear2.png)
6. api ![api](https://storage.tingqi.xyz/lear3.png)`,
    getImageSrc: () => require("../images/lear0.png"),
  },
  {
    title: "MingYou WMS",
    link: '',
    description: "MingYou WMS",
    markdown: "MingYou WMS",
    getImageSrc: () => require("../images/mingyou.png"),
  },    
];

const ProjectsSection = () => {
  
  return (
    <FullScreenSection
      backgroundColor="#CDD171"
      isDarkBackground
      p={8}
      alignItems="flex-start"
      spacing={8}
    >
      <Heading as="h1" id="projects-section">
        Projects
      </Heading>
      <Box
        display="grid"
        gridTemplateColumns="repeat(2,minmax(0,1fr))"
        gridGap={8}
      >
        {projects.map((project) => (
          <Card
            key={project.title}
            markdown={project.markdown}
            title={project.title}
            link={project.link}
            description={project.description}
            imageSrc={project.getImageSrc()}
          />
        ))}
      </Box>
    </FullScreenSection>
  );
};

export default ProjectsSection;
