import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faGithub,
  faLinkedin,
  faMedium,
  faStackOverflow,
} from "@fortawesome/free-brands-svg-icons";
import { Box, HStack } from "@chakra-ui/react";
import BurgerMenuSvg  from '../assets/burger-menu.svg'
import CloseSvg from '../assets/close.svg'

const socials = [
  {
    icon: faEnvelope,
    url: "mailto: bamboo@bambooslips.cn",
  },
  // {
  //   icon: faGithub,
  //   url: "https://github.com",
  // },
  // {
  //   icon: faLinkedin,
  //   url: "https://www.linkedin.com",
  // },
  // {
  //   icon: faMedium,
  //   url: "https://medium.com",
  // },
  // {
  //   icon: faStackOverflow,
  //   url: "https://stackoverflow.com",
  // },
];

const Header = () => {
  const [svg, setSvg] = useState(BurgerMenuSvg)
  const [ncn, setNcn] = useState('navigation')
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const boxRef = useRef(null);



  const toggleMenu = () => {
    const isBurger = svg == BurgerMenuSvg;
    const iconName = isBurger ? CloseSvg : BurgerMenuSvg;
  
    console.log(isBurger)
    setSvg(iconName)
  
    if (!isBurger) {
      setNcn("navigation navigation--mobile--fadeout");
      setTimeout(() => {
        setNcn("navigation");
      }, 300);
    } else {
      setNcn("navigation navigation--mobile");
    }
  };

  const toogleMenuButton = () => {
    const isClose = svg == CloseSvg;
    setNcn("navigation navigation--mobile--fadeout");
    setTimeout(() => {
      setNcn("navigation");
    }, 300);
    setSvg(BurgerMenuSvg)
  }
  
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const visible = prevScrollPos > currentScrollPos;
      setVisible(visible);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);
    
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);
  
  const handleClick = (anchor) => () => {
    const id = `${anchor}-section`;
    console.log(id)
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    toogleMenuButton()
  };

  return (
    <Box
      ref={boxRef}
      position="fixed"
      top={visible ? 0 : -200}
      left={0}
      right={0}
      translateY={0}
      transitionProperty="transform"
      transitionDuration=".3s"
      transitionTimingFunction="ease-in-out"
      color="#2E59A7"
      className="header-box"
      width='100vw'
    >
        
      <Box color="#2E59A7" maxWidth="100vw" width="100vw" margin="0 auto">
        <HStack
          px={16}
          py={4}
          justifyContent="space-between"
          alignItems="center"
          width='100vw'
        >

        <button onClick={() => toggleMenu()} aria-label="Open menu" className="burger-menu menu-icon" type="button">

        <img className="menu-icon" src={svg} title='Burger Menu' alt='Burger Menu'
            onClick={() => {toggleMenu()}}/>      
        </button>
            
          <nav  className={ncn}>
            <HStack>
              {/* Add social media links based on the `socials` data */}
              {socials.map((item, index) => {
                return <a  key={index}href={item.url} target="_blank"><FontAwesomeIcon icon={item.icon} size="2x" /></a>
              })}
            </HStack>
          </nav>
          <nav  className={ncn}>
            <HStack spacing={8}>
              {/* Add links to Projects and Contact me section */}
              <a onClick={handleClick('contactme')} >Contact Me</a>
              <a onClick={handleClick('projects')}>Projects</a>        
            </HStack>
          </nav>
        </HStack>
      </Box>
    </Box>
  );
};
export default Header;
