import React, {useState} from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import {BambooMarkdown} from './Markdown'

function ProjectDetails(props) {
  //const { isOpen, onOpen, onClose } = useDisclosure()
  const [size, setSize] = React.useState('full')

  const handleSizeClick = (newSize) => {
    setSize(newSize)
    // onOpen()
  }

  const handleClose = () => {
    props.setProjectDetailsIsOpen(false)
  }

  const sizes = ['xs', 'sm', 'md', 'lg', 'xl', 'full']

  return (
    <>
      <Modal onClose={handleClose} size={size} isOpen={props.projectDetailsIsOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{props.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <BambooMarkdown markdown={props.markdown}/>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export {ProjectDetails}