import React, {useState, useEffect} from "react";
import { Avatar, Heading, VStack } from "@chakra-ui/react";
import FullScreenSection from "./FullScreenSection";
import axios from "axios"


const greeting = "田松林";
// const greeting = "姓名_";
const bio1 = "工作标签_python web 全栈，web 前端";
const bio2 = "技能List_Python, JavaScript, Vue, React, Git, Docker, Linux, Proxmox, Aliyun, AWS,";

// Implement the UI for the LandingSection component according to the instructions.
// Use a combination of Avatar, Heading and VStack components.
const LandingSection = () => {
  const [bio3, setBio3] = useState('')

  const [hSize, setHSize] = useState({
    greeting: 'xs',
    bio1: 'xs',
    bio2: 'xs',  
  })


  const fetchBio = async () => {

    window.url = new URL(window.location.href)
    window.urlSearch = new URLSearchParams(url.search)

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer <your_access_token>',
      'Auth-Key': 'welcom',
      'Auth-Type': window.urlSearch.get('for')
    };
    
    const options = {
      method: 'POST',
      url: 'https://tiansonglin.tingqi.xyz/api/v1',
      headers: headers
    }

    try {
      const s = await axios.request(options)
      setBio3(s.data[0]['details'])
    } catch (e) {
      //console.log(e)
    }
    
    
  }

  
  useEffect(() => {
    fetchBio()
  },[])
  
  const handleMouseChange = (t, e) => {
    if (t=='enter') {
      const nHSize = {...hSize}
      nHSize[e] = 'xl'
      setHSize({...nHSize})
    }
    if (t=='leave') {
      const nHSize = {...hSize}
      nHSize[e] = 'xs'
      setHSize({...nHSize})
    }  
  }

  return (
  <FullScreenSection
    justifyContent="center"
    alignItems="center"
    isDarkBackground
    backgroundColor="#BED2BB"
  >
    <VStack spacing={4} align="center">
      {false ? <Avatar size="large" name={greeting}  src="https://robohash.org/example" /> : <></>}
      <Heading size={hSize.greeting} 
        >{bio3 == '' ? 'Welcom' : greeting}</Heading>   
          
      <Heading style={{
        marginTop: '10px',
        width: '80vw',
        borderRadius: '8px',
        height: '70vh',
        overflowY: 'scroll',
        padding: '10px',
        backgroundColor: '#adc0b2'
      }} size={'large'}
        
        //onMouseLeave={() => {handleMouseChange('leave', 'bio1')}}
        //onMouseEnter={() => {handleMouseChange('enter', 'bio1')}}
          >
          <p>{bio1}</p>
          <p>{bio2}</p>
          <p>{bio3}</p>  
      </Heading>
    </VStack>
  </FullScreenSection>)
}

export default LandingSection;
