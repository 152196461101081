import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {BambooTianSonglin} from './bambooTianSonglin.js'
import axios from 'axios'


async function initApp () {
  const root = ReactDOM.createRoot(document.getElementById('app'));
  root.render(
    <React.StrictMode>
      <BambooTianSonglin />
    </React.StrictMode>
  );
}

initApp();

reportWebVitals();








