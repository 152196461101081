import { Heading, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import React, {useState} from "react";
import {ProjectDetails} from './ProjectDetails'

const Card = ({ title, description, imageSrc, link, markdown }) => {
  const [projectDetailsIsOpen, setProjectDetailsIsOpen] = useState(false)

  const handleClick = (l) => {
    if (l != '') {
      window.open(l, '_blank')
    }
  }

  
  // Implement the UI for the Card component according to the instructions.
  // You should be able to implement the component with the elements imported above.
  // Feel free to import other UI components from Chakra UI if you wish to.
  return (    <VStack spacing={4} boxShadow="lg" p={4} maxW="md" borderWidth="1px" borderRadius="lg">
      <Image onClick={()=> {handleClick(link)}} src={imageSrc} alt={title} />
      <VStack spacing={2} alignItems="start">
        <Heading size="md">{title}</Heading>
        <Text>{description}</Text>
      </VStack>
      <HStack spacing={4} style={{ marginLeft: 0 }} onClick={() => {setProjectDetailsIsOpen(true)}}>
        <Text>See more</Text>
        <FontAwesomeIcon icon={faArrowRight}/>
      </HStack>
      <ProjectDetails projectDetailsIsOpen={projectDetailsIsOpen} setProjectDetailsIsOpen={setProjectDetailsIsOpen} markdown={markdown} title={title}/>
    </VStack>);
};

export default Card;
