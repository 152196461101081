import React from 'react';
import { marked } from 'marked';
import busheng0 from '../images/busheng0.png'

export default function BambooMarkdown(props) {
   const md = marked.parse(props.markdown);
   console.log(props.markdown)
   console.log(marked(md))
   const e = document.createElement('div');
   e.innerHTML = marked(md);
   return  <div dangerouslySetInnerHTML={{__html: marked(md).replace(/<a(.*?)>/g, '<a$1 target="_blank" rel="noopener noreferrer">')}}></div>;
}

export { BambooMarkdown };