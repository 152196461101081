import {useState} from "react";
import axios from "axios"


const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * This is a custom hook that can be used to submit a form and simulate an API call
 * It uses Math.random() to simulate a random success or failure, with 50% chance of each
 */
const useSubmit = () => {
  const [isLoading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const submit = async (url, data) => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer <your_access_token>',
      'Auth-Key': 'welcom',
      'Auth-Type': 'commit'
    };
    
    console.log(data)
    const options = {
      method: 'POST',
      data: data,
      url: url,
      headers: headers
    }

    const s = await axios.request(options)

    console.log(s)




    
    const random = Math.random();
    setLoading(true);
    try {
      await wait(2000);
      if (random < 0.5) {
        throw new Error("Something went wrong");
      }
      setResponse({...{
        type: 'success',
        message: `Thanks for your submission ${data.firstName}, we will get back to you shortly!`,
      }})
    } catch (error) {
      setResponse({...{
        type: 'error',
        message: 'Something went wrong, please try again later!',
      }})
    } finally {
      setLoading(false);
    }
  };

  return { isLoading, response, submit };
}

export default useSubmit;
